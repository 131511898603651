import request from "@/utils/request";

// 发送验证码接口
export function sendMobilePhoneVerificationCode(data) {
  return request({
    url: "sendMobilePhoneVerificationCode",
    method: "post",
    data
  });
}

// 注册接口
export function register(data) {
  return request({
    url: "register",
    method: "post",
    data
  });
}

// 登录接口
export function login(data) {
  return request({
    url: "login",
    method: "post",
    data
  });
}

// 找回密码接口
export function initPassword(data) {
  return request({
    url: "initPassword",
    method: "post",
    data
  });
}

// 用户信息查看接口
export function getUserInfo(data) {
  return request({
    url: "getUserInfo",
    method: "get",
		params: data
  });
}

// 用户信息修改接口
export function editUserInfo(data) {
  return request({
    url: "editUserInfo",
    method: "post",
    data
  });
}

// 我参与的赛事接口
export function userCompetition(data) {
  return request({
    url: "userCompetition",
    method: "GET",
    params: data
  });
}

// 主题赛事列表接口
export function competitionList(data) {
  return request({
    url: "competitionList",
    method: "get",
		params: data
  });
}

// 主题赛事详情接口
export function competitionDetail(data) {
  return request({
    url: "competitionDetail",
    method: "get",
		params: data
  });
}

// 报名参赛新增/修改接口
export function editCompetitionTeam(data) {
  return request({
    url: "editCompetitionTeam",
    method: "post",
    data
  });
}

// 参赛支付成功接口
export function updateTeamByPaySuccess(data) {
  return request({
    url: "updateTeamByPaySuccess",
    method: "post",
    data
  });
}

// 报名参赛团队信息展示接口
export function getCompetitionTeam(data) {
  return request({
    url: "getCompetitionTeam",
    method: "get",
    params: data
  });
}

// 作品方案上传接口
export function uploadWorksSpecifications(data) {
  return request({
    url: "uploadWorksSpecifications",
    method: "post",
		data
  });
}

// 下载作品模板接口
export function downloadWorksTemplate(data) {
  return request({
    url: "downloadWorksTemplate",
    method: "get",
    params: data
  });
}

// 提交、修改参赛作品接口
export function submitCompetitionWorks(data) {
  return request({
    url: "submitCompetitionWorks",
    method: "post",
		data
  });
}

// 参赛作品详情接口
export function getWorks(data) {
  return request({
    url: "getWorks",
    method: "get",
    params: data
  });
}

// 大赛动态列表接口
export function noticeList(data) {
  return request({
    url: "noticeList",
    method: "get",
    params: data
  });
}

// 大赛动态详情接口
export function noticeDetail(data) {
  return request({
    url: "noticeDetail",
    method: "get",
    params: data
  });
}

// 我的订单列表接口
export function myPayOrderList(data) {
  return request({
    url: "myPayOrderList",
    method: "get",
    params: data
  });
}

// 我的订单详情接口
export function myPayOrderDetail(data) {
  return request({
    url: "myPayOrderDetail",
    method: "get",
    params: data
  });
}

// 查询用户大赛状态接口
export function getUserCompetitionState(data) {
  return request({
    url: "getUserCompetitionState",
    method: "get",
    params: data
  });
}

// 废弃
// 获取决赛跳转实训平台URL接口
export function getJumpTrainingUrl(data) {
  return request({
    url: "getJumpTrainingUrl",
    method: "get",
    params: data
  });
}

// 二十三、获取帖子列表接口
export function getCommunityPostsList(data) {
  return request({
    url: "getCommunityPostsList",
    method: "get",
    params: data
  });
}

// 二十四、获取标签接口
export function getTags(data) {
  return request({
    url: "getTags",
    method: "get",
    params: data
  });
}

// 二十五、获取帖子详情接口
export function getCommunityPostsDetail(data) {
  return request({
    url: "getCommunityPostsDetail",
    method: "get",
    params: data
  });
}

// 二十六、获取帖子评论及回复数据接口
export function getCommunityPostsComment(data) {
  return request({
    url: "getCommunityPostsComment",
    method: "get",
    params: data
  });
}

// 二十七、社区帖子新增/修改接口
export function editPosts(data) {
  return request({
    url: "editPosts",
    method: "post",
    data
  });
}

// 二十八、增加帖子浏览量接口
export function incReview(data) {
  return request({
    url: "incReview",
    method: "post",
    data
  });
}

// 二十九、点赞接口
export function incLike(data) {
  return request({
    url: "incLike",
    method: "post",
    data
  });
}

// 三十、评论接口
export function editComment(data) {
  return request({
    url: "editComment",
    method: "post",
    data
  });
}

// 三十一、回复接口
export function editReplyComment(data) {
  return request({
    url: "editReplyComment",
    method: "post",
    data
  });
}

// 三十二、删除帖子接口
export function delPosts(data) {
  return request({
    url: "delPosts",
    method: "post",
    data
  });
}

// 三十三、删除评论/回复接口
export function delComment(data) {
  return request({
    url: "delComment",
    method: "post",
    data
  });
}

// 图片上传
export function uploadPic(data) {
  return request({
    url: "uploadPic",
    method: "post",
    data
  });
}

// 三十五、大赛代码上传接口
export function uploadCompetitionCode(data) {
  return request({
    url: "uploadCompetitionCode",
    method: "post",
    data
  });
}

// 二十二、开始比赛接口
export function getCompetitionTraining(data) {
  return request({
    url: "getCompetitionTraining",
    method: "get",
    params: data
  });
}

// 三十七、大赛资料下载记录日志接口
export function addDownloadLog(data) {
  return request({
    url: "addDownloadLog",
    method: "post",
    data
  });
}
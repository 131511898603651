import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
		meta: { title: '首页' },
    component: () => import('../views/HomeViewNew.vue')
  },
  {
    path: '/publish',
		meta: { title: '大赛动态' },
    name: 'trends',
    component: () => import('../views/trends.vue')
  },
	{
	  path: '/competitions/:id',
	  name: 'game',
		meta: { title: '' },  // 主题赛事
	  component: () => import('../views/game.vue')
	},
  {
    path: '/account',
    name: 'user',
		meta: { title: '个人中心' },
    component: () => import('../views/user.vue')
  },
	{
	  path: '/login',
	  name: 'login',
		meta: { title: '登录' },
	  component: () => import('../views/login.vue')
	},
	{
	  path: '/reg',
	  name: 'reg',
		meta: { title: '注册' },
	  component: () => import('../views/reg.vue')
	},
	{
	  path: '/resetpsd',
	  name: 'resetpsd',
		meta: { title: '修改密码' },
	  component: () => import('../views/resetpsd.vue')
	},
	{
	  path: '/articles/detail/:id',
	  name: 'details',
		meta: { title: '' }, // 大赛动态详情
	  component: () => import('../views/details.vue')
	},
	{
	  path: '/community',
	  name: 'community',
		meta: { title: '大赛社区' }, 
	  component: () => import('../views/community.vue')
	},
	{
	  path: '/topics/detail/:id',
		meta: { title: '' },  // 大赛社区详情
	  name: 'communityDetail',
	  component: () => import('../views/communityDetail.vue')
	},
	{
	  path: '/index1',
		meta: { title: '原首页' },  // 大赛社区详情
	  name: 'newIndex',
	  component: HomeView
	}
]

const router = new VueRouter({
	mode: 'history',
  routes
})

export default router
